import { ReactElement } from 'react'
import ChatPage from 'pages/chat/ChatPage'
import PipelinesPage from 'pages/pipelines/PipelinesPage'

export type NavRoute = {
  title: string
  path: string
  component: () => ReactElement
}

export const ROUTES = {
  pipelines: '/chat/',
  chat: '/chat/:groupSlug/:chatSlug/',
}

const navRoutes: NavRoute[] = [
  {
    title: 'Pipelines',
    path: ROUTES.pipelines,
    component: PipelinesPage,
  },
  {
    title: 'Chat',
    path: ROUTES.chat,
    component: ChatPage,
  },
]

export default navRoutes
