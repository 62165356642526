/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    delete: 'Supprimer',
    save: 'Enregistrer',
    close: 'Fermer',
    validate: 'Valider',
    send: 'Envoyer',
    search: 'Rechercher',
    errorMessages: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Une erreur est survenue.',
      serverDown: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
      videoTag: 'Votre navigateur ne supporte pas la vidéo.',
    },
    topbarTag: 'Expérimentation',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
  },
  Chat: {
    Input: {
      placeholder: 'Envoyer un message',
    },
    Message: {
      imgAlt: 'avatar du chat-SNCF',
      stopButton: 'Arrêter la lecture',
      copySuccess: 'Message copié dans le presse-papier',
      copyError: 'Erreur lors de la copie du message',
      copyButton: 'Copier',
    },
    waitingMessage: 'Chat-SNCF est en train d\'écrire',
    Snackbar: {
      createSuccess: 'Nouvelle conversation créée.',
      deleteSuccess: 'Conversation supprimée.',
      feedbackSuccess: 'Votre retour a bien été pris en compte.',
      feedbackError: 'Une erreur est survenue lors de l\'envoi de votre retour.',
    },
    buttonScrollToBottom: 'Défiler vers le bas',
    Feedback: {
      question: 'Pourquoi avez-vous choisi cette notation ?',
      Inputs: {
        response: 'Suggestion de réponse',
      },
      Snackbar: {
        sendSuccess: 'Votre commentaire a bien été envoyé.',
        updateSuccess: 'Votre commentaire a bien été mis à jour.',
      },
    },
  },
  Sidebar: {
    Buttons: {
      newChat: 'Nouveau chat',
    },
  },
} as const

export default terms
