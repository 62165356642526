import chatLogo from 'assets/logos/logo-chat.svg'
import terms from 'common/terms'
import { useAppSelector } from 'hooks'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'
import {
  ChatPipeline,
  Message as MessageInterface, UserTypes,
} from 'reducers/chat/types'
import FeedbackChat from 'components/FeedbackChat/FeedbackChat'
import { getUserInitials } from 'utils/functions'
import './Message.scss'
import LinkRenderer from '../LinkRenderer/LinkRenderer'

interface Props {
  message: MessageInterface
  messageIndex: number
  displayFeedback: boolean
  pipeline: ChatPipeline
}

function Message({
  message, messageIndex, displayFeedback, pipeline,
}: Props): ReactElement {
  const { account } = useAppSelector(state => state.user)
  const isBot = message.role === UserTypes.BOT

  const logoToDisplay = () => {
    if (isBot) {
      return (
        <img
          src={chatLogo}
          alt={terms.Chat.Message.imgAlt}
          className="chat"
        />
      )
    }
    return (
      <span className="user">
        {getUserInitials(account)}
      </span>
    )
  }

  return (
    <div>
      <div>
        <div
          className={`message ${isBot ? 'bot' : ''}`}
        >
          <div className="wrapper">
            <div className="logo">
              {logoToDisplay()}
            </div>
            <div className="response">
              <ReactMarkdown className="content" components={{ a: LinkRenderer }}>
                {message.content}
              </ReactMarkdown>
              {displayFeedback && (
                <FeedbackChat message={message} messageIndex={messageIndex} pipeline={pipeline} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Message
