/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import terms from 'common/terms'
import { ChatPipeline } from 'reducers/chat/types'
import { useAppDispatch, useAppSelector } from 'hooks'
import { getChatPipelines } from 'reducers/chat/chat.thunk'
import { v4 as uuidV4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/navRoutes'
import Card from './card/PipelineCard'
import SearchInput from './search/SearchInput'

import './PipelinesPage.scss'

const normalize = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()

export default function PipelinesPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { pipelines, loadingPipelines } = useAppSelector(state => state.chat)
  const filteredList = pipelines.filter(pipeline => {
    if (!search) return true
    const normalizeSearch = normalize(search)
    const normalizeGroup = normalize(pipeline.group)
    const normalizeName = normalize(pipeline.name)
    return normalizeGroup.includes(normalizeSearch) || normalizeName.includes(normalizeSearch)
  })

  // Generate at least 8 loading skeletons
  const getLoadingSkeleton = () => {
    if (!loadingPipelines) return null
    return Array.from({ length: 8 - (pipelines?.length || 0) }).map(
      (_, index) => <Card key={`loading-${index}`} pipeline={{} as ChatPipeline} pending />,
    )
  }

  useEffect(() => {
    dispatch(getChatPipelines())
  }, [])

  const handleClickCard = (pipeline: ChatPipeline) => () => {
    navigate(ROUTES.chat.replace(':chatSlug', pipeline.name).replace(':groupSlug', pipeline.group))
  }

  return (
    <div id="pipelines">
      <div className="actions">
        <SearchInput
          placeholder={terms.Common.search}
          onChange={(value: string) => setSearch(value)}
        />
      </div>
      <div className="wrapper">
        {filteredList.map(pipeline => (
          <Card key={uuidV4()} pipeline={pipeline} handleClick={handleClickCard(pipeline)} />
        ))}
        {getLoadingSkeleton()}
      </div>
    </div>
  )
}
