// ENUMS

export enum UserTypes {
  USER = 'user',
  BOT = 'assistant',
}

export enum Thumbs {
  up = 1,
  down = 0,
}

// TYPES

export type ChatState = {
  chat: Chat | null,
  chatList: Chat[],
  pipelines: ChatPipeline[],
  loadingPipelines: boolean,
}

export type Message = {
  role: UserTypes,
  content: string,
}

export type Chat = {
  id: string,
  name: string,
  last_updated: string,
  content: {
    messages: Message[],
  }
}

export type MessageFeedback = {
  messageId: number,
  feedbackType: Thumbs,
  answerProposal: string | null,
}

export type ChatPipeline = {
  group: string,
  name: string,
}
