import Chat from 'components/Chat/Chat'
import './ChatPage.scss'

function Home() {
  return (
    <div className="home">
      <Chat />
    </div>
  )
}

export default Home
