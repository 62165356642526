import { ReactElement, useState } from 'react'
import { Thumbs, Message as MessageInterface, ChatPipeline } from 'reducers/chat/types'
import { giveMessageFeedback } from 'reducers/chat/chat.thunk'
import { useAppDispatch, useAppSelector } from 'hooks'
import ThumbsInput from './ThumbsInput/ThumbsInput'
import FeedbackForm from './FeedbackForm/feedbackForm'

import './FeedbackChat.scss'

interface Props {
  message: MessageInterface
  messageIndex: number
  pipeline: ChatPipeline
}

const FeedbackChat = ({ message, messageIndex, pipeline }:Props): ReactElement => {
  const dispatch = useAppDispatch()
  const { chat } = useAppSelector(state => state.chat)
  const [thumb, setThumb] = useState<Thumbs | null>(null)
  const [suggestion, setSuggestion] = useState<string | null>(null)

  const handleClickThumb = (thumbValue: Thumbs) => {
    setThumb(thumbValue)
    if (thumbValue === Thumbs.up && chat?.id) {
      dispatch(giveMessageFeedback({
        chatId: chat.id,
        feedback: {
          messageId: messageIndex,
          feedbackType: thumbValue,
          answerProposal: null,
        },
        pipeline,
      }))
    }
  }

  const handleClickSend = () => {
    if (chat?.id) {
      dispatch(giveMessageFeedback({
        chatId: chat.id,
        feedback: {
          messageId: messageIndex,
          feedbackType: Thumbs.down,
          answerProposal: suggestion,
        },
        pipeline,
      }))
    }
  }
  return (
    <div className="feedback-chat">
      <ThumbsInput
        handleClick={handleClickThumb}
        thumb={thumb}
        message={message}
      />
      {
        (thumb === Thumbs.down) && (
          <FeedbackForm
            suggestion={suggestion}
            setSuggestion={setSuggestion}
            handleClickSend={handleClickSend}
          />
        )
      }
    </div>
  )
}

export default FeedbackChat
