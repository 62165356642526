import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createChat, deleteChat, getChatById, getChatList,
  getChatPipelines,
} from './chat.thunk'
import { ChatState, Message } from './types'

const initialState: ChatState = {
  chat: null,
  chatList: [],
  pipelines: [],
  loadingPipelines: true,
}

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<{chat: string, message: Message}>) => {
      if (!state.chat) return
      const chatMessages = [...state.chat.content.messages, action.payload.message]
      state.chat.content.messages = chatMessages
      state.chatList = state.chatList.map(item => {
        if (item.id === action.payload.chat) {
          item.content.messages = chatMessages
        }
        return item
      })
    },
    updateMessageContent(state, action: PayloadAction<{ id: number, additionalContent: string }>) {
      if (!state.chat?.content.messages.at(action.payload.id)) return
      const newContent = [...action.payload.additionalContent].map(c => c.charCodeAt(0)).length === 0 ? '\n'
        : action.payload.additionalContent
      state.chat.content.messages[action.payload.id].content += newContent
    },
    removeChat: state => {
      state.chat = null
    },
  },
  extraReducers(builder) {
    builder.addCase(getChatList.fulfilled, (state, { payload }) => {
      state.chatList = payload.results
    })
    builder.addCase(getChatById.fulfilled, (state, { payload }) => {
      state.chat = payload
    })
    builder.addCase(deleteChat.fulfilled, (state, arg) => {
      if (state.chat?.id === arg.meta.arg.id) state.chat = null
      state.chatList = state.chatList.filter(item => item.id !== arg.meta.arg.id)
    })
    builder.addCase(createChat.fulfilled, (state, { payload }) => {
      state.chat = payload
      state.chatList.push(payload)
    })
    builder.addCase(getChatPipelines.fulfilled, (state, { payload }) => {
      state.pipelines = payload
      state.loadingPipelines = false
    })
    builder.addCase(getChatPipelines.pending, state => {
      state.loadingPipelines = true
    })
    builder.addCase(getChatPipelines.rejected, state => {
      state.loadingPipelines = false
    })
  },
})

export const {
  addMessage,
  updateMessageContent,
  removeChat,
} = chat.actions

export default chat.reducer
