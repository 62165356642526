import { UserAccount } from '@osrdata/app_core/dist/auth/redux/actions'
import terms from 'common/terms'
import { setSnackbar } from 'reducers/feedback/feedback.reducers'
import { SnackbarSeverity } from 'reducers/feedback/types'
import { store } from 'reducers/store'

/**
 * @param user: UserAccount
 * @returns { string }
 * @description
 * This function is used to get the user initials
*/
type GetUserInitials = (user: UserAccount) => string
const getUserInitials: GetUserInitials = user => `${user.firstName[0]}${user.lastName[0]}`

const handleCopyMessage = (text: string) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      store.dispatch(setSnackbar({
        message: terms.Chat.Message.copySuccess,
        severity: SnackbarSeverity.SUCCESS,
      }))
    })
    .catch(() => {
      store.dispatch(setSnackbar({
        message: terms.Chat.Message.copyError,
        severity: SnackbarSeverity.ERROR,
      }))
    })
}

export {
  getUserInitials,
  handleCopyMessage,
}
