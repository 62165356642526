import { ReactElement } from 'react'
import { ChatPipeline } from 'reducers/chat/types'

import './PipelineCard.scss'
import SimpleButton from 'components/SimpleButton/SimpleButton'

type Props = {
  pipeline: ChatPipeline
  pending?: boolean
  handleClick?: () => void
}

function PipelineCard({ pipeline, pending, handleClick }: Props): ReactElement {
  return (
    <div className={`card-project ${pending ? 'pending' : ''}`}>
      <h2>{pipeline.name}</h2>
      <p>{pipeline.group}</p>
      <SimpleButton title="Voir" onClick={handleClick || (() => undefined)} />
    </div>
  )
}

PipelineCard.defaultProps = {
  pending: false,
  handleClick: undefined,
}

export default PipelineCard
