import { AnchorHTMLAttributes } from 'react'
import './LinkRenderer.scss'

export default function LinkRenderer(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { children, href } = props
  if (href?.includes('/llmp/download')) {
    const handleClick = async () => {
      try {
        const response = await fetch(href, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        })
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error downloading file', error)
      }
    }

    return (
      <button type="button" className="link" onClick={handleClick}>
        {children}
      </button>
    )
  }
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}
