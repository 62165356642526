import AddIcon from '@mui/icons-material/Add'
import terms from 'common/terms'
import CustomButton, { ButtonVariant } from 'components/CustomButton/CustomButton'
import { useAppDispatch, useAppSelector } from 'hooks'
import { ReactElement, useState } from 'react'
import { removeChat } from 'reducers/chat/chat.reducers'
import { createChat, getChatById, getChatList } from 'reducers/chat/chat.thunk'
import { ChatPipeline } from 'reducers/chat/types'
import ChatList from './ChatList/ChatList'

import './Sidebar.scss'

interface Props {
  activePipeline: ChatPipeline | null
}

function Sidebar({ activePipeline }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { chatList, chat } = useAppSelector(state => state.chat)
  const [displaySidebar, setDisplaySidebar] = useState(true)
  const handleCreateChat = () => {
    if (!activePipeline) return
    dispatch(removeChat())
    dispatch(createChat(activePipeline))
  }

  const handleClickChat = (id: string) => {
    if (!activePipeline) return
    dispatch(removeChat())
    dispatch(getChatById({
      id, pipeline: activePipeline,
    }))
    dispatch(getChatList(activePipeline))
  }

  return (
    <>
      <button
        type="button"
        className={`button-sidebar ${displaySidebar ? 'close' : 'open'}`}
        onClick={() => setDisplaySidebar(!displaySidebar)}
      >
        <AddIcon className="icon" />
      </button>
      <div className={`sidebar ${displaySidebar ? 'visible' : 'hidden'}`}>
        <div className="new-chat">
          <CustomButton
            title={terms.Sidebar.Buttons.newChat}
            className="button"
            variant={ButtonVariant.secondary}
            icon={<AddIcon />}
            handleClick={handleCreateChat}
          />
        </div>
        <ChatList
          chatList={chatList}
          currentChatId={chat?.id || null}
          handleClickChat={handleClickChat}
          activePipeline={activePipeline}
        />
      </div>
    </>
  )
}

export default Sidebar
