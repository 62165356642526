import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { deleteChat, giveMessageFeedback } from 'reducers/chat/chat.thunk'
import { FeedbackState, SnackbarSeverity } from './types'

const initialState: FeedbackState = {
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: undefined,
}

export const feedback = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = undefined
    },
  },
  extraReducers(builder) {
    builder.addCase(deleteChat.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Chat.Snackbar.deleteSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(giveMessageFeedback.fulfilled, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Chat.Snackbar.feedbackSuccess
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    })
    builder.addCase(giveMessageFeedback.rejected, state => {
      state.displaySnackbar = true
      state.snackbarMessage = terms.Chat.Snackbar.feedbackError
      state.snackbarSeverity = SnackbarSeverity.ERROR
    })
  },
})

export const {
  setSnackbar,
  hideSnackbar,
} = feedback.actions

export default feedback.reducer
