import { ReactElement, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import MessageList from 'components/MessageList/MessageList'
import {
  createChat,
  getChatList,
  getChatPipelines,
} from 'reducers/chat/chat.thunk'
import Waiting from 'components/Waiting/Waiting'
import SendInput from 'components/SendInput/SendInput'
import Sidebar from 'components/Sidebar/Sidebar'
import { ChatPipeline, Chat as ChatType, UserTypes } from 'reducers/chat/types'
import MAIN_API from 'config/config'
import terms from 'common/terms'
import './chat.scss'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { addMessage, updateMessageContent } from 'reducers/chat/chat.reducers'
import { store } from 'reducers/store'
import { useParams } from 'react-router-dom'

const runPipeline = async (
  message: string,
  chat: ChatType,
  abortController: AbortController,
  pipeline: ChatPipeline,
) => {
  const pipelineUrl = `${MAIN_API.api}/llmp/v1/pipelines/${pipeline.group}/${pipeline.name}/run/`
  const newMessageIndex = chat.content.messages.length + 1
  try {
    await fetchEventSource(
      pipelineUrl,
      {
        method: 'POST',
        body: JSON.stringify({
          input_text: message,
          pipeline_configuration: {
            chat_id: chat.id,
          },
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        signal: abortController.signal,
        async onopen(response) {
          if (response.status >= 400) {
            throw new Error(`Server responded with status : ${response?.status}`)
          }
          store.dispatch(addMessage({
            chat: chat.id,
            message: {
              content: '',
              role: UserTypes.BOT,
            },
          }))
        },
        onmessage: event => {
          store.dispatch(updateMessageContent({
            id: newMessageIndex,
            additionalContent: event.data,
          }))
        },
        onerror(e) {
        // eslint-disable-next-line no-console
          console.error(e)
          throw new Error(e)
        },
      },
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function Chat(): ReactElement {
  const dispatch = useAppDispatch()
  const { chatSlug } = useParams()
  const { chat, pipelines } = useAppSelector(state => state.chat)
  const [message, setMessage] = useState('')
  const [abortController, setAbortController] = useState(new AbortController())
  const [streamPending, setStreamPending] = useState<boolean>(false)
  const [activePipeline, setActivePipeline] = useState<ChatPipeline | null>(null)

  useEffect(() => {
    if (pipelines.length === 0) {
      dispatch(getChatPipelines())
    }
  }, [])

  useEffect(() => {
    const ap = pipelines.find(p => p.name === chatSlug)
    setActivePipeline(ap || null)
  }, [chatSlug, pipelines])

  useEffect(() => {
    if (activePipeline) {
      dispatch(getChatList(activePipeline))
    }
  }, [activePipeline])

  const handleSend = async () => {
    if (streamPending || message === '' || !activePipeline) return
    setStreamPending(true)
    if (chat) {
      dispatch(addMessage({
        chat: chat.id,
        message: {
          content: message,
          role: UserTypes.USER,
        },
      }))
      setMessage('')
      await runPipeline(message, chat, abortController, activePipeline)
    } else {
      await dispatch(createChat(activePipeline)).then(async r => {
        const newChatId = r?.payload?.id
        if (newChatId) {
          dispatch(addMessage({
            chat: newChatId,
            message: {
              content: message,
              role: UserTypes.USER,
            },
          }))
          setMessage('')
          await runPipeline(message, r.payload, abortController, activePipeline)
        }
      })
    }
    setStreamPending(false)
  }

  const handleClickStopAnimation = () => {
    abortController.abort()
    setAbortController(new AbortController())
  }

  return (
    <>
      <Sidebar activePipeline={activePipeline} />
      <div className="chat">
        {activePipeline && (
          <MessageList
            messages={chat?.content.messages || []}
            streamPending={streamPending}
            pipeline={activePipeline}
          />
        )}
        <div>
          {streamPending && (
            <div>
              <Waiting
                waitingMessage={terms.Chat.waitingMessage}
              />
            </div>

          )}
          <SendInput
            message={message}
            setMessage={setMessage}
            handleSend={handleSend}
            placeholder={terms.Chat.Input.placeholder}
            handleClickStopAnimation={handleClickStopAnimation}
            streamPending={streamPending}
          />
        </div>
      </div>
    </>
  )
}

export default Chat
