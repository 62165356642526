import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import CircleDownIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import { ChatPipeline, Message as MessageInterface, UserTypes } from 'reducers/chat/types'
import terms from 'common/terms'
import { TooltipLight } from 'components/Theme/TooltipLight'
import { v4 as uuidV4 } from 'uuid'
import Message from './Message/Message'

interface Props {
  messages: MessageInterface[] | [];
  streamPending: boolean;
  pipeline: ChatPipeline;
}

function MessageList({
  messages, streamPending, pipeline,
}: Props): ReactElement {
  const messageListRef = useRef<HTMLDivElement>(null)
  const [displayScrollButton, setDisplayScrollButton] = useState<boolean>(false)

  const [messagesDisplayed, setMessagesDisplayed] = useState<MessageInterface[]>(messages)

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = 0
    }
  }

  const handleScroll = () => {
    const isAtBottom = messageListRef.current?.scrollTop === 0
    setDisplayScrollButton(!isAtBottom)
  }

  useEffect(() => {
    setMessagesDisplayed(messages)
  }, [messages])

  useEffect(() => {
    if (messageListRef.current) {
      const { scrollTop } = messageListRef.current
      setDisplayScrollButton(scrollTop < 0)
    }
  }, [messageListRef.current?.scrollTop])

  if (!messages) return <div />

  const reverseMessages = [...messagesDisplayed].reverse()
  return (
    <div
      className="messages"
      ref={messageListRef}
      onScroll={handleScroll}
    >
      {reverseMessages.map((item, index) => (
        <Message
          key={uuidV4()}
          message={item}
          messageIndex={reverseMessages.length - index - 1}
          displayFeedback={item.role === UserTypes.BOT && !streamPending && index === 0}
          pipeline={pipeline}
        />
      ))}
      {displayScrollButton && (
        <div className="scroll-button">
          <TooltipLight
            title={terms.Chat.buttonScrollToBottom}
            placement="top"
          >
            <button
              type="button"
              onClick={() => {
                scrollToBottom()
                setDisplayScrollButton(false)
              }}
            >
              <CircleDownIcon />
            </button>
          </TooltipLight>
        </div>
      )}
    </div>
  )
}

export default MessageList
