import { ReactElement } from 'react'
import { Chat, ChatPipeline, UserTypes } from 'reducers/chat/types'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useAppDispatch } from 'hooks'
import { deleteChat } from 'reducers/chat/chat.thunk'

interface Props {
  chatList: Chat[]
  handleClickChat: (id: string) => void
  currentChatId: string | null
  activePipeline: ChatPipeline | null
}

function ChatList({
  chatList, handleClickChat, currentChatId, activePipeline,
}: Props): ReactElement {
  const dispatch = useAppDispatch()
  const handleDelete = (id: string) => {
    if (!activePipeline) return
    dispatch(deleteChat({
      id, pipeline: activePipeline,
    }))
  }

  const getChatName = (chat: Chat) => {
    const lastMessage = chat.content.messages.filter(m => m.role === UserTypes.USER).at(-1)
    return lastMessage?.content || 'Nouveau chat'
  }

  return (
    <div className="chat-list">
      {
        chatList.map(chat => (
          <div key={chat.id} className="conversation">
            <button
              type="button"
              className={`button ${currentChatId === chat.id ? 'active' : ''}`}
              onClick={() => handleClickChat(chat.id)}
            >
              <ChatBubbleOutlineIcon className="icon" />
              <span className="content">{getChatName(chat)}</span>
            </button>
            <button type="button" className="delete-button" onClick={() => handleDelete(chat.id)}>
              <DeleteForeverIcon className="icon" />
            </button>
          </div>
        ))
      }

    </div>
  )
}

export default ChatList
