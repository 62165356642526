import { TextField } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'

interface Props {
  suggestion: string | null
  setSuggestion: (value: string) => void
  handleClickSend: () => void
}

const FeedbackForm = ({
  suggestion,
  setSuggestion,
  handleClickSend,
}: Props): ReactElement => (
  <div className="feedback-input">
    <div className="separation" />
    <p>
      {terms.Chat.Feedback.question}
    </p>
    <TextField
      placeholder={terms.Chat.Feedback.Inputs.response}
      className="textarea"
      size="small"
      value={suggestion}
      onChange={e => setSuggestion(e.target.value)}
      id="feedback-chat-response"
      multiline
      rows={3}
    />
    <div className="button">
      <button
        type="button"
        onClick={handleClickSend}
      >
        {terms.Common.send}
      </button>
    </div>
  </div>
)

export default FeedbackForm
