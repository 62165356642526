import { TextField } from '@mui/material'
import { ReactElement, useRef } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import './SendInput.scss'
import { TooltipLight } from 'components/Theme/TooltipLight'
import { StopCircle } from '@mui/icons-material'
import terms from 'common/terms'

interface Props {
  message: string
  setMessage: (message: string) => void
  handleSend: () => void
  placeholder: string
  handleClickStopAnimation: () => void
  streamPending: boolean
}

function SendInput({
  message,
  setMessage,
  handleSend,
  placeholder,
  handleClickStopAnimation,
  streamPending,
}: Props): ReactElement {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  if (inputRef.current) {
    inputRef.current.focus()
  }

  return (
    <div className="input">
      <TextField
        inputRef={inputRef}
        className="textarea"
        size="medium"
        value={message}
        onChange={e => setMessage(e.target.value)}
        onKeyPress={onKeyPress}
        id="outlined-multiline-static"
        placeholder={placeholder}
        maxRows={7}
        multiline
        InputProps={{
          endAdornment: streamPending ? (
            <button
              type="button"
              className="button"
            >
              <TooltipLight
                title={terms.Chat.Message.stopButton}
                placement="top"
              >
                <StopCircle
                  className="icon"
                  onClick={handleClickStopAnimation}
                />
              </TooltipLight>
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSend}
              className="button"
            >
              <ArrowForwardIcon className="icon" />
            </button>
          ),
        }}
      />
    </div>
  )
}

export default SendInput
