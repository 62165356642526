import {
  deleteRequest, get, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChatPipeline, MessageFeedback } from './types'

const getChatList = createAsyncThunk(
  'chat/getChatList',
  async (params: ChatPipeline, thunkAPI) => {
    try {
      const response = await get(`/llmp/chats/${params.group}/${params.name}/`)

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getChatById = createAsyncThunk(
  'chat/getChatById',
  async (params : {id: string, pipeline: ChatPipeline}, thunkAPI) => {
    try {
      const response = await get(`/llmp/chats/${params.pipeline.group}/${params.pipeline.name}/${params.id}/`)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const createChat = createAsyncThunk(
  'chat/createChat',
  async (params: ChatPipeline, thunkAPI) => {
    try {
      const response = await post(`/llmp/chats/${params.group}/${params.name}/`, {})
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const deleteChat = createAsyncThunk(
  'chat/deleteChat',
  async (params : {id: string, pipeline: ChatPipeline}, thunkAPI) => {
    try {
      const response = await deleteRequest(`/llmp/chats/${params.pipeline.group}/${params.pipeline.name}/${params.id}/`)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const giveMessageFeedback = createAsyncThunk(
  'chat/giveMessageFeedback',
  async (params: { chatId: string, feedback: MessageFeedback, pipeline: ChatPipeline }, thunkAPI) => {
    try {
      // eslint-disable-next-line max-len
      const response = await post(`/llmp/chats/${params.pipeline.group}/${params.pipeline.name}/${params.chatId}/feedbacks/`, {
        kind: params.feedback.feedbackType,
        content: params.feedback.answerProposal,
        message_index: params.feedback.messageId,
      })
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getChatPipelines = createAsyncThunk(
  'chat/getChatPipelines',
  async (_, thunkAPI) => {
    try {
      const response = await get('/llmp/v1/pipelines/', { chat_compatible: true })
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export {
  getChatList,
  getChatById,
  createChat,
  deleteChat,
  giveMessageFeedback,
  getChatPipelines,
}
