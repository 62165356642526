import { ReactElement } from 'react'
import thumbUp from 'assets/icons/thumbs-up.png'
import thumbDown from 'assets/icons/thumbs-down.png'
import thumbDownBlack from 'assets/icons/thumbs-down-black.png'
import thumbUpBlack from 'assets/icons/thumbs-up-black.png'
import { Thumbs, Message as MessageInterface } from 'reducers/chat/types'
import CopyButton from 'components/CopyButton/CopyButton'

interface Props {
  handleClick: (thumb: Thumbs) => void
  thumb: Thumbs | null
  message: MessageInterface
}

const ThumbsInput = ({ handleClick, thumb, message }: Props): ReactElement => {
  const generateTextToCopy = () => message.content

  return (
    <div className="thumbs">
      <button
        type="button"
        onClick={() => handleClick(Thumbs.up)}
        className={`button ${thumb === Thumbs.up ? 'active' : ''}`}
      >
        <img src={thumb === Thumbs.up ? thumbUp : thumbUpBlack} alt="hand down" />
      </button>
      <button
        type="button"
        onClick={() => handleClick(Thumbs.down)}
        className={`button ${thumb === Thumbs.down ? 'active' : ''}`}
      >
        <img src={thumb === Thumbs.down ? thumbDown : thumbDownBlack} alt="hand down" />
      </button>
      <div className="copy-button">
        <CopyButton text={generateTextToCopy()} />
      </div>
    </div>
  )
}

export default ThumbsInput
